:root {
	--mainOpacity: rgba(0, 0, 0, 0.55);
}

.header {
	background-image: linear-gradient(rgba(143, 143, 143, 0.7), rgba(0, 0, 0, 0.7)), url("./Assets/images/stadium.jpg");
	background-size: cover;
	opacity: var(--mainOpacity);
	height: 100% !important;
}

.dark-overlay {
	background-color: rgba(0, 0, 0, 0.35);
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.main-color {
	background-color: #0e76a8;
}

.homepage-carousel-title {
	display: flex !important;
	justify-content: center !important;
	align-items: center !important;
}

.book-div {
	width: 480px;
	height: 384px;
}

.inactiveLink {
	pointer-events: none;
	cursor: default;
}
